(function($) {
  $(document).ready(function() {
    var menu = $('.js-menuclick');
    function openMenu( menu ){
      $(menu).on('click', function(e) {
        //e.preventDefault();
        $(this).children().stop(true).toggleClass('isOpen');
      });
    }

    openMenu( menu );
  });
})(jQuery);
