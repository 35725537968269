(function($) {
  $(document).ready(function() {

    function checkWidth() {
      var windowsize  = $(window).width();
      if(windowsize <= 638 ) {
        $('.js-menuclick').click(function(e){
          e.preventDefault();
          $(this).siblings().slideToggle('slow');
        });
      }
    }

    var win = $(window).resize(checkWidth);

    checkWidth();

  });
})(jQuery);
