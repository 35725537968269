(function($) {
    /**  Form Submissions
    *********************/
    jQuery( document ).on( 'cf.form.submit', function ( event, data ) {
        fbq('track', 'Contact');
    });

    /**  Join Today
    *****************************/
    $("a[href$=' https://forms.joinmycu.com/mop/166/mop/promotional']").click(function() {
        fbq('track', 'Lead');
    });

    /**  Apply
    *****************************/
    $("a[href$='https://loans.itsme247.com/166/Home/Start/']").click(function() {
        fbq('track', 'SubmitApplication');
    });
    
    /**  Calculator
    *****************************/
    $("a[href$='https://dmcu.com/loans/loan-calculators/auto-loan-calculator/']").click(function() {
        fbq('track', 'CustomizeProduct');
    });

})(jQuery);