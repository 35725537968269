(function($) {
    /**  Form Submissions
    *********************/

    jQuery( document ).on( 'cf.form.submit', function ( event, data ) {
      function setFromPage() {
        // Current Page Title (first part)
        var title = document.title;
            title = title.split(" |", 1).pop();
        // Send GA Event
        return ga('send', 'event', 'Form', 'Submit', title);
      }
      setFromPage();
    });

    jQuery( document ).on("gform_confirmation_loaded", function(event, formID) {
      if(formID == 1) {
        ga('send', 'event', 'Form', 'Submit', 'Give Back Checking');
      } else if(formID == 2) {
        ga('send', 'event', 'Form', 'Submit', 'Financial Coaching');
      }
    });

    /**  Online Banking Toggle
    *****************************/
    $("#ob-trigger").click(function() {
      ga('send', 'event', 'Online Banking', 'click');
    });

    /**  Live Chat Button
    *****************************/
    $(".bottom-toggle__chat").click(function() {
      ga('send', 'event', 'Live Chat', 'click');
    });

    /** Front Page
    ******************************/

    // CTA CARDS
    // $('ga-autoLoansRates').click( function() {
    //   ga('send', 'event', 'Auto Loan Rates', 'click');
    // });
    //
    // // RATES
    // $('ga-autoLoansRates').click( function() {
    //   ga('send', 'event', 'Auto Loan Rates', 'click');
    // });
    //
    // $('ga-homeEquityRates').click( function() {
    //   ga('send', 'event', 'Home Equity Rates', 'click');
    // });
    //
    // $('ga-creditCardRates').click( function() {
    //   ga('send', 'event', 'Credit Card Rates', 'click');
    // });
    //
    // $('ga-personalLoansRates').click( function() {
    //   ga('send', 'event', 'Personal Loans Rates', 'click');
    // });
    //
    // $('ga-consolidationRates').click( function() {
    //   ga('send', 'event', 'Consolidtion Rates', 'click');
    // });
    //
    // $('ga-studentRates').click( function() {
    //   ga('send', 'event', 'Student Rates', 'click');
    // });

    /** Footer
    ******************************/

    // PHONE NUMBER
    $('ga-mainphone').click( function() {
      ga('send', 'event', 'Phone', 'click', 'Main 313 568-5000');
    });
    $('ga-caymphone').click( function() {
      ga('send', 'event', 'Phone', 'click', 'CAYM 313 202-9388');
    });
    $('ga-clawmphone').click( function() {
      ga('send', 'event', 'Phone', 'click', 'Clawson 248 435-0950');
    });
    $('ga-noviphone').click( function() {
      ga('send', 'event', 'Phone', 'click', 'Novi 248 277-3628');
    });


    /** LOCATIONS
    ******************************/

    // Share Branches
    /*
    * This is in the body content of the page as a text link
    */
    $('ga-shared').click( function(){
      ga('send', 'event', 'Shared Branch ATMS', 'click');
    });


    /*
    * Skip-A-Pay this is in the body of the content.
    */
    $('ga-mobilebank').click( function(){
      ga('send', 'event', 'Skip-A-Pay Mobile Banking', 'click');
    });

    /*
    * GreenPath this is in the body of the content.
    */
    $('ga-greenpath').click( function(){
      ga('send', 'event', 'GreenPath Financial Wellness', 'click');
    });

    /*
    * Savvy Money this is in the body of the content.
    */
    $('ga-savvymoney').click( function() {
      ga('send', 'event', 'Savvy Money Online Banking Login', 'click');
    });


    $('ga-apple').click( function() {
      ga('send', 'event', 'Apple App Store', 'click');
    });

    $('ga-google').click( function() {
      ga('send', 'event', 'Google App Store', 'click');
    });


    // Personal Accounts / Retirement body of content
    $('ga-retirement').click( function() {
      ga('send', 'event', 'Retirement Portal', 'click');
    });


    // Personal Accounts / Should You Delay body of content
    $('ga-ssa').click( function() {
      ga('send', 'event', 'Social Security Benefits', 'click');
    });

    // Personal Accounts / Should You Delay body of content
    $('ga-rewards').click( function() {
      ga('send', 'event', 'DMCU Rewards - Footer', 'click');
    });


})(jQuery);
