(function($) {
  $(document).ready(function() {
    var trigger = $('#ob-trigger');
    var obContainer = $('.bottom-toggle__online');
    
    trigger.click(function(){
      toggleNav();
    });

    function toggleNav(){
      if(obContainer.hasClass('show') ) {
        obContainer.removeClass('show');
      } else {
        obContainer.addClass('show');
      }
    }
  });

})(jQuery);
