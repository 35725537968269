(function($) {
  $(document).ready(function() {

    var helper = $('#helperOverlay');
    var helperClose = '';

    function overlayShow() {
      helper.addClass( 'showOver' );
    }

    function overlayHide() {
      helper.removeClass('showOver');
    }

    $('.showOverlay').click( function(e) {
      e.preventDefault();
      overlayShow();
    });

    $('.hideOverlay').click( function() {
      overlayHide();
    });


  });
})(jQuery);
