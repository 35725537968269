(function($) {
    $(document).ready(function() {
        // SPEED BUMP connected to a class added.
        var $speedbmp = $('.speed-bump');
        var $spdbmp;

        $speedbmp.click(function(e){
            $spdbmp = confirm("You are now leaving the Diversified Members Credit Union (DMCU) web site for one operated by a third party..\n\nDMCU has no responsibility for an external web site. It neither endorses the information, content, presentation. or accuracy nor make any warranty, express or implied, regarding any external site.\n\nThank you for visiting Diversified Members Credit Union."); 
            if($spdbmp != true) {
                e.preventDefault();
            }
        });

        var $speedbmp2 = $('.speed-bump-texts');
        var $spdbmp2;

        $speedbmp2.click(function(e){
            $spdbmp2 = confirm("Stay Informed!\n\nOpting in for text messages allows us to provide you with quicker updates on your loan application. While it's not required, it helps us respond faster to your inquiries.\n\nThank you for considering this option!"); 
            if($spdbmp2 != true) {
                e.preventDefault();
            }
        });
    });

})(jQuery);