(function($) {
//  $("#siteModal").modal('show');

//  $(window).load(function(){    
//     var Modal = document.getElementById('siteModal'); 
//     var key = 'hadModal',
//     hadModal = localStorage.getItem(key);
    
//     if (!hadModal) {
//         Modal.style.display = "block";
//         localStorage.setItem(key, true);
//     }
    
//     }); 

})(jQuery);